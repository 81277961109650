import { t } from '@lingui/macro';
import {
  colorFn,
  Divider,
  FacebookIcon,
  InstagramIcon,
  Link,
  MiloFull,
  MILO_COMMERCIAL_PRESENTATION_PDF,
  NewsletterForm,
} from '@milo/react';
import { FC, useMemo } from 'react';

import { SOCIAL_FACEBOOK_URL, SOCIAL_INSTAGRAM_URL } from '@milo/constants';

type FooterProps = {
  showCTA?: boolean;
  variant?: 'full' | 'base' | 'minimal';
};

export const Footer: FC<FooterProps> = ({ variant = 'base' }) => {
  const footerLinks = useMemo(() => {
    const links = {
      experiences: { name: t`Parcourir les expériences`, href: 'experiences' },
      activate: { name: t`Acheter un pass M ta région`, href: 'purchase' },
      download: { name: t`Téléchargez l'app`, href: 'app-download' },
      offer: { name: t`Offrir un pass M ta région en cadeau`, href: 'purchase', params: { purchaseMode: 'offer' } },
      terms: { name: t`Conditions d'utilisation`, href: 'terms' },
      about: { name: t`À propos de nous`, href: 'about' },
      faq: { name: t`Questions fréquentes`, href: 'faq' },

      // Change pdf for that link
      'become-partner': {
        name: t`Devenir partenaire`,
        href: "mailto:partenaire@mtaregion.com",
      },

      'member': {
        name: t`Membre M ta région`,
        href: "membre@mtaregion.com",
      },

      'partner-login': { name: t`Connexion Partenaire`, href: 'partner/login' },
      // 'partner-guide': {
      //   name: t`Guide du Partenaire`,
      //   href: 'partner/guide',
      //   params: { type: 'partner' },
      //   className: 'lg:col-start-5',
      // },
    };

    if (variant === 'full' || variant === 'base') {
      return [
        links['experiences'],
        links['offer'],
        links['about'],
        links['become-partner'],
        links['activate'],
        links['terms'],
        links['faq'],
        links['member'],
        links['download'],
        null,
        links['partner-login'],
      ];
    }

    return [links['faq']];
  }, [variant]);

  return (
    <footer className="bg-charcoal">
      <div className="mx-auto max-w-7xl p-5">
        <NewsletterForm />

        <Divider.thin className="mb-10 bg-medium-dark" />

        <div
          className={`${
            variant === 'minimal' ? 'md:hidden' : ''
          }  mx-auto mb-4 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5`}
        >
          {variant !== 'minimal' && (
            <div className="col-span-1 row-span-4">
              <MiloFull fill="white" mColor={colorFn('charcoal')} className="mb-10 w-28" />
            </div>
          )}

          {footerLinks.map((link) => {
            if (!link?.name) {
              return <span key={Math.random()}></span>;
            }
            return (
              <Link
                key={link?.name}
                {...link}
                className={`${link.className ?? ''} flex text-sm leading-[22px] text-white`}
              >
                {link.name}
              </Link>
            );
          })}

          <div className="mt-6 flex items-center space-x-4 text-white md:hidden">
            <Link href={SOCIAL_FACEBOOK_URL}>
              <FacebookIcon className="h-6 w-6" />
            </Link>
            <Link href={SOCIAL_INSTAGRAM_URL}>
              <InstagramIcon className="h-6 w-6" />
            </Link>
            {/* <Link href={SOCIAL_LINKEDIN_URL}>
              <LinkedInIcon className="h-6 w-6" />
            </Link> */}
          </div>
        </div>

        <div className="mt-7 flex items-center justify-between pt-4 md:mt-0 md:pt-0">
          <div className="flex items-center space-x-6">
            <p className="mx-auto text-sm leading-[22px] text-gray-light md:text-center">
              © M ta Région Inc. {new Date().getFullYear()}
            </p>
            {variant === 'minimal' && (
              <div className="hidden items-center space-x-6 md:flex">
                {footerLinks.map((link) => {
                  if (!link?.name) {
                    return <span key={Math.random()}></span>;
                  }
                  return (
                    <Link
                      key={link?.name}
                      {...link}
                      className={`${link.className ?? ''} flex text-sm leading-[22px] text-white`}
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
          <div className="hidden items-center space-x-4 text-white md:flex">
            <Link href={SOCIAL_FACEBOOK_URL}>
              <FacebookIcon className="h-6 w-6" />
            </Link>
            <Link href={SOCIAL_INSTAGRAM_URL}>
              <InstagramIcon className="h-6 w-6" />
            </Link>
            {/* <Link href={SOCIAL_LINKEDIN_URL}>
              <LinkedInIcon className="h-6 w-6" />
            </Link> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
